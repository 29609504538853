module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Regard","short_name":"Regard","description":"Regard je developerská společnost založená v roce 1999. Naším záměrem je\n        stavět kvalitní a dostupné bydlení v oblasti Prahy.","lang":"cs","start_url":"/","background_color":"#ffffff","theme_color":"#0b001a","display":"browser","icon":"src/img/icon.png","localize":[{"start_url":"/en","lang":"en","name":"Regard","short_name":"Regard","description":"Regard is a development company founded in 1999. Our mission is to build\n            quality, affordable housing in the Prague area."},{"start_url":"/ru","lang":"ru","name":"Regard","short_name":"Regard","description":"Regard - это девелоперская компания, основанная в 1999 году. Наша миссия -\n            построить качественное и доступное жилье в районе Праги."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"eecd464bc08e7a93739a29bb03e05207"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-169913254-1","head":true,"anonymize":true,"respectDNT":false,"exclude":["/admin/**"],"pageTransitionDelay":300,"defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
